.milk-drinks-section {
  padding-top: 3rem;
  color: $heading-color;
  font-family: $noto;

  @media #{$sm} {
    padding-top: 2rem;
  }

  .content-wrap {
    background-color: $white;

    .image-wrap {
      width: 100%;
      max-height: 500px;
      height: 500px;

      // background-color: #fff6da;
      // box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.1);
      @media #{$sm} {
        max-height: 200px;
      }

      .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        // border-radius: 6px;
      }
    }

    .description {
      #heading {
        font-weight: 500;
        font-size: 16px;
        color: $primary-color;
        margin: 1.2rem 0%;
      }

      h5 {
        margin-bottom: 1.2rem;
        font-family: $noto;
        color: $heading-color;
        font-weight: bold;
        font-size: 18px;
      }

      h6,
      h3 {
        margin-bottom: 1.2rem;
        font-family: $noto;
        color: $heading-color;
        font-weight: bold;
        font-size: 18px;
        // &:not(:first-child) {
        margin-top: 1.5rem;

        // }
        li {
          display: flex;
          align-items: center;

          @media #{$sm} {
            text-align: justify;
          }

          &::before {
            content: "\2713";
            color: $white;
            font-size: 10px;
            font-weight: bold;
            text-align: center;
            margin-right: 0.5rem;
            padding: 0.25rem 0.25rem;
            background-color: $primary-color;
            border-radius: 41% 59% 44% 63% / 43% 43% 57% 57%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 16px;
            height: 16px;
          }
        }
      }

      p {
        margin-bottom: 1rem;

        @media #{$sm} {
          text-align: justify;
        }
      }

      ol {
        margin-bottom: 1rem;

        // list-style-type: none;
        // padding-left: 1rem;
        @media #{$sm} {
          text-align: justify;
        }

        li {
          // display: flex;
          // align-items: center;
          margin-bottom: 0.4rem;

          @media #{$sm} {
            text-align: justify;
          }
        }
      }

      ul {
        margin-bottom: 1rem;
        list-style-type: none;
        padding-left: 0rem;

        li {
          display: block;
          // align-items: center;
          // align-items: baseline;
          margin-bottom: 0.4rem;

          @media #{$sm} {
            text-align: justify;
          }

          &::before {
            content: "\2713";
            color: $white;
            font-size: 10px;
            font-weight: bold;
            text-align: center;
            margin-right: 0.5rem;
            padding: 0.25rem 0.25rem;
            background-color: $primary-color;
            border-radius: 41% 59% 44% 63% / 43% 43% 57% 57%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 16px;
            height: 16px;
          }
        }
      }

      img {
        margin: 0.6rem 0%;

        @media #{$sm} {
          width: 100%;
        }
      }

      table {
        width: 100% !important;
        border-color: #868686;
        border: 1px solid #868686;
        border-collapse: collapse;

        tr {
          height: 10vh;

          td {
            padding-left: 2%;

            &:nth-child(1) {
              min-width: 6vw;
              // text-align: center;
            }

            &:nth-child(2) {
              // padding-left: 2%;
            }
          }
        }
      }

      strong {
        color: $primary-color;
        font-weight: 500;
      }

      table {
        margin-bottom: 1rem;
      }

      td {
        strong {
          color: $text-secondary;
        }
      }
    }

    .text-wrap {
      padding: 2rem 0rem;

      @media #{$sm} {
        padding: 1rem 0rem;
      }

      h2 {
        // font-size: 36px;
        color: $primary-color;
        margin-bottom: 0%;
        font-family: $noto;
        margin-top: 0.7rem;
        margin-bottom: 1rem;

        @media #{$sm} {
          font-size: 28px;
          margin-top: 0.3rem;
          text-align: left;
        }
      }

      .detailed-image {
        width: 100%;
        height: 350px;
        border: 1px solid #eee;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          object-fit: contain;
          object-position: center;
          // height: 100%;
          width: 100%;
        }
      }

      .icons-wrap {
        width: 50%;
        display: inline-flex;
        justify-content: space-between;
        color: $heading-color;
        opacity: 0.5;
        font-size: 14px;
        font-weight: 500;
        margin: 1.2rem 0%;

        // margin-bottom: 0%;
        .icon-span-wrap {
          width: auto;
          display: flex;
          justify-content: space-evenly;
          align-items: center;

          @media #{$sm} {
            width: auto;
          }

          @media #{$md} {
            width: 130px;
          }

          .icon {
            font-size: 16px;

            @media #{$sm} {
              font-size: 25px;
            }

            @media #{$md} {
              font-size: 25px;
            }
          }
        }

        @media #{$md} {
          width: 100%;
          text-align: center;
        }

        @media #{$sm} {
          width: 100%;
          text-align: center;
        }

        @media #{$xsm} {
          width: 100%;
          display: flex;
          justify-content: space-evenly;
          margin: 0.6rem;
        }
      }

      img {
        margin-bottom: 1rem;
      }
    }
  }
}