// .category-filters {
//   padding: 0 1rem;
//   @media #{$sm} {
//     flex-wrap: wrap;
//     padding: 0;
//   }
//   .Dropdown-root {
//     width: 48%;
//     @media #{$sm} {
//       width: 100%;
//       margin-bottom: 0.5rem;
//     }
//     .Dropdown-control {
//       border: 1px solid $primary-color;
//       color: $primary-color;
//       text-align: left;
//       border-radius: 4px;
//       @include padding-vertical(6, "px");
//       font-size: 15px;
//     }
//   }
//   select {
//     display: block;
//     width: 100%;
//     height: calc(1.5em + 0.75rem + 2px);
//     padding: 0.375rem 0.75rem;
//     font-size: 1rem;
//     font-weight: 400;
//     line-height: 1.5;
//     color: #495057;
//     background-color: #fff;
//     background-clip: padding-box;
//     border: 1px solid #ced4da;
//     border-radius: 0.25rem;
//     transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
//   }
// }

// .sort-filters {
//   align-items: center;
//   padding: 0 1rem;
//   @media #{$sm} {
//     padding: 0;
//   }
//   .Dropdown-root {
//     width: 59%;
//     @media #{$sm} {
//       width: 100%;
//     }
//     .Dropdown-control {
//       border: 1px solid $primary-color;
//       color: $primary-color;
//       text-align: left;
//       border-radius: 4px;
//       @include padding-vertical(6, "px");
//       font-size: 15px;
//     }
//     .Dropdown-menu {
//       text-align: left;
//     }
//   }
//   .item-count {
//     width: 39%;
//     font-size: 14px;
//     @media #{$sm} {
//       display: none;
//     }
//   }
//   .item-count-Arabic {
//     width: 39%;
//     font-size: 14px;
//     text-align: left !important;
//     @media #{$sm} {
//       display: none;
//     }
//   }
//   select {
//     display: block;
//     width: 100%;
//     height: calc(1.5em + 0.75rem + 2px);
//     padding: 0.375rem 0.75rem;
//     font-size: 1rem;
//     font-weight: 400;
//     line-height: 1.5;
//     color: #495057;
//     background-color: #fff;
//     background-clip: padding-box;
//     border: 1px solid #ced4da;
//     border-radius: 0.25rem;
//     transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
//   }
// }


// // --------new--style----------
// .cat-f-w{ 
//   flex-wrap: wrap; padding-left: 0; padding-right: 0; margin-top: 20px;
//   select{width: 100% !important;}
// }

// .product-grid-filters .col-sm-4{ margin-left: auto;}
// .cat-f-w{
//   select{
//     margin-bottom: 20px !important;
//   }
// }



// //-------------new-filter-style---
// .category-list {
//   padding: 0;
//   margin: 0 0 40px 0;
//   > li{ list-style: none;
//   background: transparent;
//   border-bottom: solid 1px #ccc;
//   button{  background: transparent url(../../../assets/images/arrow-fltr.png) 95% center no-repeat;
//   width: 100%;
//   border: none;
//   outline: none;
//   text-align: left;
//   font-size: 14px;
//   padding-top: 10px;
//   padding-bottom: 10px;
//   &.active{ color: #e65550;
//   font-weight: 600;
//   background: transparent url(../../../assets/images/arrow-fltr-a.png) 95% center no-repeat;
// }
  
//   }
// }

// .child-categories {
//   margin: 0;
//   padding: 0 0px  0px 20px;
//   li{ list-style: none;
//   button{ background: transparent;
//   &.active{font-weight: 600;
// }
//     }

//   }
// }
// }




.category-filters {
  padding: 0 1rem;
  @media #{$sm} {
    flex-wrap: wrap;
    padding: 0;
  }
  .Dropdown-root {
    width: 48%;
    @media #{$sm} {
      width: 100%;
      margin-bottom: 0.5rem;
    }
    .Dropdown-control {
      border: 1px solid $primary-color;
      color: $primary-color;
      text-align: left;
      border-radius: 4px;
      @include padding-vertical(6, "px");
      font-size: 15px;
    }
  }
  select {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}

.sort-filters {
  align-items: center;
  padding: 0 1rem;
  @media #{$sm} {
    padding: 0;
  }
  .Dropdown-root {
    width: 59%;
    @media #{$sm} {
      width: 100%;
    }
    .Dropdown-control {
      border: 1px solid $primary-color;
      color: $primary-color;
      text-align: left;
      border-radius: 4px;
      @include padding-vertical(6, "px");
      font-size: 15px;
    }
    .Dropdown-menu {
      text-align: left;
    }
  }
  .item-count {
    width: 39%;
    font-size: 14px;
    @media #{$sm} {
      display: none;
    }
  }
  .item-count-Arabic {
    width: 39%;
    font-size: 14px;
    text-align: left !important;
    @media #{$sm} {
      display: none;
    }
  }
  select {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}


// --------new--style----------
.cat-f-w{ 
  flex-wrap: wrap; padding-left: 0; padding-right: 0; margin-top: 20px;
  select{width: 100% !important;}
}

.product-grid-filters .col-sm-4{ margin-left: auto;}
.cat-f-w{
  select{
    margin-bottom: 20px !important;
  }
}



//-------------new-filter-style---
.category-list {
  padding: 0;
  margin: 0 0 40px 0;
  > li{ list-style: none;
  background: transparent;
  border-bottom: solid 1px #ccc;
  button{ 
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: transparent;
  &.active{ color: #e65550;
  font-weight: 600;
 
}
  
  }

  &.hasChild{

    > button{
      background: transparent url(../../../assets/images/arrow-fltr.png) 95% center no-repeat;
      &.active{
        background: transparent url(../../../assets/images/arrow-fltr-a.png) 95% center no-repeat;
      }
    }

   

  }
}

.child-categories {
  margin: 0;
  padding: 0 0px  0px 20px;
  li{ list-style: none;
  button{ background: transparent;
  &.active{font-weight: 600;
}
    }

  }
}
}

