.profile-tab-container {
  @include padding-vertical(5, "rem");
  font-family: $noto;
  color: $heading-color;
  @media #{$sm} {
    padding-top: 2rem;
    padding-bottom: 1.3rem;
  }
  .tabs-wrapper {
    // @include padding-vertical(2, "rem");
    // box-shadow: 0 0 5px rgba($color: #000000, $alpha: .1);
    .nav-pills {
      height: 100%;
      border-right: 3px solid $secondary-color;
      // padding-right: .25rem;
      // justify-content: center;
      padding-top: 2rem;
      @media #{$sm} {
        padding-top: 0%;
      }
      .nav-item {
        .nav-link {
          color: $text-secondary;
          font-weight: 500;
          border-bottom: 1px solid $primary-color;
          border-radius: 0;
          display: flex;
          align-items: center;
          &.active {
            color: $white;
            background-color: $primary-color;
            border-radius: 0;
            border-bottom: 0;
          }
        }
      }
    }
    .tab-pane {
      min-height: 40vh;
      .tab-content {
        .tab-title {
          margin-bottom: 2rem;
          display: inline-block;
          border-bottom: 3px solid $primary-color;
          padding-bottom: 0.25rem;
          @media #{$sm} {
            margin-bottom: 1rem;
            margin-top: 1rem;
          }
        }
        .profile-avatar {
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 6px;
            @extend .img-thumbnail;
            // @extend .rounded-circle;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .noPaddingLeft {
    padding-left: 0;
    border-radius: 0px 0.25rem 0.25rem 0px;
  }
  .mobileCode {
    border-radius: 0.25rem 0px 0px 0.25rem;
  }
  .mobileNumberEdit {
    border-radius: 0px 0.25rem 0.25rem 0px;
  }

  .noPaddingRight {
    padding-right: 0;
    border-radius: 0.25rem 0px 0px 0.25rem;
  }
}


.tabs-wrapper {
  @media (max-width: 768px) {
    max-width: 90%;
  }
  @media (max-width: 575px) {
    width: 90%;
  }
}

.width40percent {
  @media (max-width: 575px) {
    width: 40%;
  }

}
.width50percent {
  @media (max-width: 575px) {
    width: 60%;
  }
  @media (max-width: 575px) {
    width: 60%;
  }

}

