.order-tracking {
    .form-row {
        margin-top: 50px;
        text-align: center;
        input#tracking_number {
            background: #f6f6f6;
            max-width: 400px;
            margin: 0 auto;
        }
        button {
            margin-top: 20px;
        }
        .track-heading {
            span {
                display: inline;
                img {
                    max-width: 50px;
                    margin-right: 10px;
                }
            }
        }
    }
    .tracking-details-row {
        margin-top: 100px;
        .error-code{
            text-align: center;
        }
        hr{
            max-width: 200px;
        }
        .track-box {
            text-align: center;
            span {
                font-weight: bold;
                display: block;
                color: #e65550;
            }
        }
        .order-point{
            margin-top: 25px;
            margin-left: 20px;
            &:before{
                content: '';
                width: 15px;
                height: 15px;
                display: block;
                position: absolute;
                background: #e65550;
                left: -5px;
                border-radius: 50%;
                margin-top: 8px;
            }
            &:first-child{
                margin-top: 0px;
            }
            &:after {
                position: absolute;
                content: '';
                width: 1px;
                height: 60px;
                background: #ccc;
                left: 2px;
                margin-top: -30px;
            }
            &:last-child{
                &:after{
                    content: none;
                }
            }
        }
    }
    // $breakpoint-tablet: 768px;
    @media (max-width: 900px) {
        .tracking-details-row{
            margin-top: 50px;     
            .timeline-column{
                text-align: center;
                // margin-top: 50px;
                // margin-bottom: 50px;
                .order-point{
                    &:before{
                        left: 15px;
                    }
                    &:after{
                        left: 21px;
                        margin-top: -40px;
                    }
                }
            }
        }
    }

    @media (max-width: 550px) {
        .tracking-details-row{
            margin-top: 50px; 
            hr{
                max-width: 500px;
            }    
            .timeline-column{
                text-align: center;
                margin-top: 50px;
                margin-bottom: 50px;
                .order-point{
                    &:before{
                        left: 15px;
                    }
                    &:after{
                        left: 21px;
                    }
                }
            }
        }
    }
}