.blog-category-grid {
  margin-top: 2rem;
  .blog-grid-content {
    border-radius: 6px;
    box-shadow: 0px 0px 25px rgba($color: #000000, $alpha: 0.05);
    // min-height: 400px;
    margin-bottom: 2rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &:hover {
      box-shadow: 0px 0px 25px rgba($color: #000000, $alpha: 0.08);
    }
    .blog-image-wrap {
      text-align: center;
      width: 100%;
      height: 225px;
      background-color: transparent;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .blog-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 6px;
      }
    }
    .blog-text {
      // padding: 3% 5%;
      margin-top: 1rem;
      color: $primary-color;
      @media #{$sm} {
        text-align: justify;
      }
      p {
        color: $text-secondary;
        font-size: 14px;
        height: 100px;
        // font-weight: 300;
      }
      .read-more {
        color: $primary-color;
        cursor: pointer;
      }
      // @include simpleButton(200, none);
      .blogdesc {
        h3 {
          color: $text-secondary;
          font-size: 14px;
        }
      }
    }
    @media #{$sm} {
      margin-bottom: 2rem;
      min-height: auto;
    }
  }
}

// //!------------
// @-webkit-keyframes ripple-white {
//   0% {
//     -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1),
//       0 0 0 10px rgba(255, 255, 255, 0.1),
//       0 0 0 20px rgba(255, 255, 255, 0.1);
//     box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1),
//       0 0 0 10px rgba(255, 255, 255, 0.1),
//       0 0 0 20px rgba(255, 255, 255, 0.1);
//   }
//   100% {
//     -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.1),
//       0 0 0 20px rgba(255, 255, 255, 0.1),
//       0 0 0 30px rgba(255, 255, 255, 0);
//     box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.1),
//       0 0 0 20px rgba(255, 255, 255, 0.1),
//       0 0 0 30px rgba(255, 255, 255, 0);
//   }
// }

// @keyframes ripple-white {
//   0% {
//     -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1),
//       0 0 0 10px rgba(255, 255, 255, 0.1),
//       0 0 0 20px rgba(255, 255, 255, 0.1);
//     box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1),
//       0 0 0 10px rgba(255, 255, 255, 0.1),
//       0 0 0 20px rgba(255, 255, 255, 0.1);
//   }
//   100% {
//     -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.1),
//       0 0 0 20px rgba(255, 255, 255, 0.1),
//       0 0 0 30px rgba(255, 255, 255, 0);
//     box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.1),
//       0 0 0 20px rgba(255, 255, 255, 0.1),
//       0 0 0 30px rgba(255, 255, 255, 0);
//   }
// }

// .video-promo-content {
//   background-color: rgb(158, 138, 138);
//   width: 150px;
//   height: 150px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

// .video-play-icon {
//   width: 50px;
//   height: 50px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background: #fff;
//   border-radius: 50%;
//   -webkit-animation: ripple-white 1s linear infinite;
//   animation: ripple-white 1s linear infinite;
//   -webkit-transition: 0.5s;
// }

// //!------------
