body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color    : #f0f0f0 !important; */
  padding-top            : 72px;
}

body .arabic-direction {
  font-family: "Droid Arabic Naskh", serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  margin: 0.67rem 0;
}

@media only screen and (min-device-width : 1024px) and (max-device-width : 1200px) {

  /* STYLES GO HERE */
  .navbar-wrap .navbar .navbar-brand {
    width: 130px !important;
  }

  .navbar-expand-lg .navbar-nav {
    margin-left : 0 !important;
    padding-left: 0 !important;
  }

  .navbar-wrap .navbar .nav-link {
    font-size: 10px !important;
    padding  : 5px !important;
  }

  .btn-sm,
  .btn-group-sm>.btn {
    padding  : 5px !important;
    font-size: 13px !important;
  }

  .navbar-icons svg {
    font-size: 15px;
  }
}

@media only screen and (max-width : 767px) {
  p {
    font-size: 15px !important;
  }

  /* nav.navbar.navbar-expand-lg.navbar-light.bg-light.fixed-top {
    right     : 145px;
    box-shadow: 0 0 5px rgb(0 0 0 / 30%);
  } */
}

/* Product Main Listing */
@media (max-width: 767px) {
  .product-grid-filters select {
    width: 100% !important;
  }
}



/* Landing Page CSS start here */
@font-face {
  font-family: Oscine;
  src: url("./assets/font/Oscine-Regular.otf");
}
@font-face {
  font-family: Oscine_Bold;
  src: url("./assets/font/Oscine-Bold.otf");
}
@font-face {
  font-family: Oscine_ExtraBold;
  src: url("./assets/font/Oscine-ExtraBold.otf");
}

.landing-main-class {
  font-family: Oscine;
  font-style: normal;
  font-weight: normal;
}

.landing-main-class img {
  width: 100%;
}

.__desktop-banner {
  display: none;
}

.__mobile-banner {
  display: block;
}

/* Media query for small screens and up */
@media (min-width: 640px) {
  .__desktop-banner {
    display: block;
  }

  .__mobile-banner {
    display: none;
  }
}

.__section-padding-small {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 3rem;
  padding-top: 3rem;
}

.__section-padding-large {
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 3rem;
}

.__max-width-4xl {
  max-width: 64rem;
  margin-left: auto;
  margin-right: auto;
}

.__text-center {
  text-align: center;
}

.__padding-4 {
  padding: 1rem;
}

.__text-primary {
  color: #d95e56;
}

.__text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.__text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.__font-bold {
  font-weight: 700;
}

.__margin-bottom-4 {
  margin-bottom: 1rem;
}

.__text-gray {
  color: gray;
}

.__text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.__font-thin {
  font-weight: 300;
}

.__text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.__text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.__z-index-30 {
  z-index: 30;
}

/* Media queries */
@media (min-width: 640px) {
  .__section-padding-small {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media (min-width: 768px) {
  .__text-2xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .__text-base {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

.__landing-bg-primary {
  background-color: #e5dddb;
}

.__landing-py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.__landing-text-heading {
  font-size: 1.5rem;
  font-weight: bold;
  color: #d95e56;
  padding-left: 5px;
  padding-right: 5px;
}

@media (min-width: 640px) {
  .__landing-text-heading {
    font-size: 2.5rem;
    padding: 0px;
  }
}

.__landing-mb-4 {
  margin-bottom: 1rem;
}

.__landing-text-center {
  text-align: center;
}

.__landing-container {
  max-width: 72rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.__landing-grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 2.5rem;
  place-items: center;
}

@media (min-width: 640px) {
  .__landing-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .__landing-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.__landing-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  border-radius: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 24rem;
  padding: 2.5rem;
}

.__landing-img-wrapper {
  background-color: white;
  border-radius: 50%;
}

.__landing-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.__landing-text {
  color: #4a4a4a;
  margin-bottom: 1rem;
  text-align: center;
}

.__landing-card-footer {
  position: absolute;
  bottom: -0.75rem;
  left: 0;
  width: 100%;
}

.__landing-card-footer-bg {
  background-color: #a1d1dd;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  text-align: center;
  border-radius: 0.75rem;
}

.__landing-card-footer-text {
  color: black;
  font-size: 1.125rem;
  font-weight: bold;
}

.__support-section {
  position: relative;
  padding-left: 0;
  padding-right: 0;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-image: url("./assets/images/pigeonLandingPage/fifthsection_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 640px) {
  .__support-section {
    padding-left: 4rem;
    padding-right: 4rem;
    background-image: url("./assets/images/pigeonLandingPage/element_02.png");
  }
}

.__support-container {
  width: 100%;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.__support-heading {
  color: #d95e56;
  padding-top: 5.5rem;
  padding-bottom: 1.5rem;
  font-size: 2.25rem;
  font-weight: bold;
  z-index: 20;
  text-align: center;
}

.arabic-direction .__support-heading {
  color: #d95e56;
  padding-bottom: 1.5rem;
  padding-top: 5.5rem;
  font-size: 2.25rem;
  font-weight: bold;
  z-index: 20;
  text-align: center;
}


@media (min-width: 640px) {
  .__support-heading {
    font-size: 3rem;
    padding-top: 1.5rem;
  }
}
@media (min-width: 640px) {
  .arabic-direction .__support-heading {
    font-size: 3rem;
    padding-top: 1.5rem;
  }
}

.__support-hidden-sm {
  display: none;
}

@media (min-width: 640px) {
  .__support-hidden-sm {
    display: block;
  }
}

.__support-hidden-lg {
  display: block;
}

@media (min-width: 640px) {
  .__support-hidden-lg {
    display: none;
  }
}

.__baby-section {
  position: relative;
  background-color: white;
  overflow: hidden;
}

.__baby-hidden-sm {
  display: none;
}

@media (min-width: 640px) {
  .__baby-hidden-sm {
    display: block;
  }
}

.__baby-hidden-lg {
  display: block;
}

@media (min-width: 640px) {
  .__baby-hidden-lg {
    display: none;
  }
}

.__baby-absolute {
  position: absolute;
}

.__baby-bottom-40 {
  bottom: 10rem;
}

@media (min-width: 640px) {
  .sm\:__baby-bottom-10 {
    bottom: 2.5rem;
  }
}

.__baby-opacity-95 {
  opacity: 0.95;
}

.__baby-content {
  background-color: white;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  text-align: center;
}

@media (min-width: 640px) {
  .english-direction .__baby-content {
    padding-left: 5rem;
    padding-right: 5rem;
    text-align: left;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
}

.__baby-text {
  color: #d95e56;
  font-size: 1rem;
  font-weight: 600;
}

@media (min-width: 640px) {
  .__baby-text {
    font-size: 1.5rem;
  }
}

.arabic-direction .__baby-text {
  color: #d95e56;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
}

@media (min-width: 640px) {
  .arabic-direction .__baby-text {
    font-size: 1.5rem;
  }
}

@media (min-width: 640px) {
  .arabic-direction .__baby-content {
    padding-right: 5rem;
    padding-left: 5rem;
    text-align: right;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
}

.__research-section {
  position: relative;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

@media (min-width: 640px) {
  .__research-section {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

.__research-bg {
  position: absolute;
  inset: 0;
  background-size: cover;
  background-position: center;
  opacity: 0.2;
  background-image: url("./assets/images/pigeonLandingPage/bg1.jpg");
}

.__research-heading {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2.5rem;
  color: #d95e56;
}

@media (min-width: 640px) {
  .__research-heading {
    font-size: 4rem;
  }
}

.__research-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
  position: relative;
}

@media (min-width: 1280px) {
  .__research-cards-container {
    gap: 2rem;
  }
}

.__research-card {
  width: 100%;
  display: flex;
  align-items: center;
}

@media (min-width: 640px) {
  .__research-card {
    width: 50%;
  }
}

@media (min-width: 1024px) {
  .__research-card {
    width: 33.333333%;
  }
}

.__research-card-img-wrapper {
  position: relative;
  width: 50%;
  z-index: 10;
  background-color: white;
  border-radius: 50%;
}

.english-direction .__research-card-content-wrapper {
  position: relative;
  width: 100%;
  height: auto;
  left: -2.5rem;
  z-index: 0;
}

.english-direction .__research-card-content {
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  padding-left: 2.5rem;
  padding-right: 1.25rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.english-direction .__research-card-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #d95e56;
  margin-bottom: 0.5rem;
  text-align: left;
}

.english-direction .__research-card-text {
  font-size: 0.875rem;
  color: #4a4a4a;
  text-align: left;
}

@media (min-width: 640px) {
  .english-direction .__research-card-content-wrapper {
    left: -2.5rem;
  }
}

@media (min-width: 1280px) {
  .english-direction .__research-card-content-wrapper {
    left: -2.5rem;
  }
}

.arabic-direction .__research-card-content-wrapper {
  position: relative;
  width: 100%;
  height: auto;
  right: -2.5rem;
  z-index: 0;
}

.arabic-direction .__research-card-content {
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  padding-right: 2.5rem;
  padding-left: 1.25rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.arabic-direction .__research-card-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #d95e56;
  margin-bottom: 0.5rem;
  text-align: right;
}

.arabic-direction .__research-card-text {
  font-size: 0.875rem;
  color: #4a4a4a;
  text-align: right;
}

@media (min-width: 640px) {
  .arabic-direction .__research-card-content-wrapper {
    right: -2.5rem;
  }
}

@media (min-width: 1280px) {
  .arabic-direction .__research-card-content-wrapper {
    right: -2.5rem;
  }
}

.__study-section {
  background-color: #dce7ec;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
}

.__study-container {
  max-width: 72rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .__study-container {
    flex-direction: row;
  }
}

.__study-video-wrapper {
  width: 100%;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .__study-video-wrapper {
    width: 66.666667%;
    margin-bottom: 0;
  }
}

.__study-iframe {
  width: 100%;
  height: 15rem;
}

@media (min-width: 640px) {
  .__study-iframe {
    padding-left: 4rem;
    padding-right: 4rem;
    height: 100%;
  }
}

.__study-text-wrapper {
  width: 100%;
  padding-left: 0;
  padding-right: 2rem;
}

@media (min-width: 768px) {
  .__study-text-wrapper {
    width: 33.333333%;
    padding-left: 0.5rem;
    padding-right: 2rem;
  }
}

@media (min-width: 640px) {
  .__study-text-wrapper {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.__study-heading {
  font-size: 1.875rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.__study-text {
  font-size: 1rem;
}

.__learn-container {
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@media (min-width: 640px) {
  .__learn-container {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

.__learn-heading {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
  color: #d95e56;
}

@media (min-width: 640px) {
  .__learn-heading {
    font-size: 1.875rem;
  }
}

.__learn-grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1rem;
}

@media (min-width: 768px) {
  .__learn-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

.__learn-video-wrapper {
  position: relative;
  padding-top: 56.25%;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  height: 15rem;
}

@media (min-width: 640px) {
  .__learn-video-wrapper {
    height: 100%;
  }
}

.__learn-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.product-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@media (min-width: 640px) {
  .product-section {
    padding-left: 4rem;
    padding-right: 4rem;
    text-align: center;
  }
}

.product-section .show-more{
  background-color: #e5dddb;
}

.product-section .show-more-arrow {
  background-color: #e5dddb;
  border-radius: 50%;
  border: 1px solid white;
  text-align: center;
  bottom: -15px;
  width: 25px;
  cursor: pointer;
}
.product-section .show-more-arrow path:last-child{
  stroke-width: 2;
}
.product-section .div-style {
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.product-section .fade-in {
  opacity: 1;
  transform: translateY(0);
}

.parent-test:hover .show-more{
  background-color: #d95e56;
  color: white;
}
.parent-test:hover .show-more-arrow{
  background-color: #d95e56;
  color: white;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1rem;
  align-items: center;
}

@media (min-width: 768px) {
  .product-grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 1.25rem;
  }
}

.product-header {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.english-direction .product-header-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  color: #d95e56;
}

.arabic-direction .product-header-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  color: #d95e56;
}

@media (min-width: 640px) {
  .product-header {
    grid-column: 1 / 2;
  }
  .product-header-title {
    font-size: 1.875rem;
  }
}
@media (min-width: 768px) {
  .english-direction .product-header-title ,
  .arabic-direction .product-header-title {
    font-size: 2.5rem;
  }

  .english-direction .nipples-title {
    text-align: left;
  }
  .arabic-direction .nipples-title {
    text-align: right;
  }
}

@media (min-width: 768px) {
  .product-header-title {
    font-size: 3rem;
  }
}

.product-header-subtitle {
  font-size: 1.5rem;
}

@media (min-width: 640px) {
  .product-header-subtitle {
    font-size: 1.875rem;
  }
}

@media (min-width: 768px) {
  .product-header-subtitle {
    font-size: 3.75rem;
  }
}

@media (min-width: 768px) {
  .arabic-direction .product-header-subtitle {
    font-size: 2.4rem;
  }
}

.product-header-break {
  display: none;
}

@media (min-width: 768px) {
  .product-header-break {
    display: block;
  }
}

.product-slider-container {
  width: 100%;
  padding-left: 1.25rem;
  padding-right: 0;
}

@media (min-width: 640px) {
  .product-slider-container {
    padding-left: 0;
    grid-column: 2 / 5;
  }
}

.slider-container {
  /* overflow: hidden; */
}

.product-slider {
  position: relative;
}

.product-slide {
  padding-left: 1rem;
  padding-right: 1rem;
}

.product-card {
  background-color: white;
  padding: 1.5rem;
  overflow: visible;
  /* box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); */
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: auto;
  height: 100%;
  border: 1px solid #E2E2E2;
}
.product-card:hover {
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.product-size {
  position: absolute;
  top: 1.25rem;
  left: 0;
  background-color: #d95e56;
  color: white;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.75rem;
}

.product-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.product-name {
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
  margin-bottom: 2rem;
  min-height: 3rem;
}

.product-button-container {
  margin-top: auto;
  width: 100%;
}

.product-button {
  background-color: #e5dddb;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0.75rem;
  font-size: 1.25rem;
  font-weight: bold;
  border: none;
}
.product-button:hover {
  background-color: #d95e56;
  color: white;
}

.productsSlider .slick-prev {
  z-index: 20;
}

.productsSlider .slick-prev:before {
  content: url('./assets/images/pigeonLandingPage/prev.webp');
}

.productsSlider .slick-next:before {
  content: url('./assets/images/pigeonLandingPage/next.webp');
}